import React from 'react';

const Footer = () => {
    return(
        <>
            <footer id="footer">
                <div className="container">
                    <h3>El housseyn arab</h3>
                    <div className="social-links">
                        <a href="https://www.linkedin.com/in/elhousseyn-arab" target='_blank' className="linkedin"><i className="bx bxl-linkedin"></i></a>
                        <a href="https://github.com/arabelhousseyn" target='_blank' className="github"><i className="bx bxl-github"></i></a>
                    </div>
                    <div className="copyright">
                        &copy; Copyright <strong><span>Elhousseyn arab</span></strong>. All Rights Reserved
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Footer;