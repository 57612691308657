import React, {useState} from 'react';
import profile from '../assets/img/profile.jpg';
import emailjs from '@emailjs/browser';
import { Oval } from 'react-loader-spinner';
import app from '../config/app';

const Section = () => {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [failed, setFailed] = useState(false);

    const [inputs, setInputs] = useState({name : '', message: '', subject: '', email: ''});

    const frontend_stack = [
        {
            title : "Html",
            value : 90,
            progress :
                {
                    now: 90,
                    min:0,
                    max:100
                }
        },

        {
            title : "Css",
            value : 90,
            progress :
                {
                    now: 90,
                    min:0,
                    max:100
                }

        },

        {
            title : "Javascript",
            value : 90,
            progress :
                {
                    now: 90,
                    min:0,
                    max:100
                }
        },

        {
            title : "Typescript",
            value : 90,
            progress :
                {
                    now: 90,
                    min:0,
                    max:100
                }
        },

        {
            title : "React",
            value : 50,
            progress :
                {
                    now: 50,
                    min:0,
                    max:100
                }
        },
    ];

    const backend_stack = [
        {
            title : "Nodejs",
            value : 60,
            progress :
                {
                    now: 60,
                    min:0,
                    max:100
                }
        },

        {
            title : "PHP",
            value : 90,
            progress :
                {
                    now: 90,
                    min:0,
                    max:100
                }

        },

        {
            title : "Laravel",
            value : 90,
            progress :
                {
                    now: 90,
                    min:0,
                    max:100
                }
        },

        {
            title : "Bash script",
            value : 50,
            progress :
                {
                    now: 50,
                    min:0,
                    max:100
                }
        },
    ];

    const resume = [
        {
            jobTitle: 'backend developer at kaizTech',
            startYear: 'Aug. 2021',
            endYear: 'Apr. 2022',
            workingPlace: 'Algiers',
            responsibilities:[
                'Responsible for system architecture.',
                'Database designing and optimizing.',
                'Designing api\'s.',
            ]
        },

        {
            jobTitle: 'Software engineer at sahla solution',
            startYear: 'May. 2022',
            endYear: 'Sep. 2022',
            workingPlace: 'Algiers',
            responsibilities:[
                'Led and managed a team of software developers and ensured the successful delivery of projects.',
                'Led highly productive team of developers in backend development cycle.',
                'Worked with project managers, developers, quality assurance and customers to resolve technical issues.',
                'Trained and mentored junior developers and engineers, teaching skills in Software Engineering and working to improve overall team performance.'
            ]
        },

        {
            jobTitle: 'Software engineer at dzconseil',
            startYear: 'Sep. 2022',
            endYear: 'Aug. 2023',
            workingPlace: 'Algiers',
            responsibilities:[
                'Responsible for deployment of the project to the customers.',
                'system and database designing.',
                'Led technical backend developers.',
                'Devops.'
            ]
        },

        {
            jobTitle: 'Software engineer at blinx',
            startYear: 'Aug. 2023',
            endYear: 'Present',
            workingPlace: 'UAE',
            responsibilities:[
                'Designing and developing custom solutions using Nodejs, magnolia and php (laravel)',
                'Collaborating with the development team to ensure that all solutions are delivered on time and meet the required quality standards.',
                'Troubleshooting and fixing any issues that arise during the development process.',
                'Participating in code reviews to ensure that all solutions are of high quality.',
                'Staying up-to-date with new technologies.'
            ]
        },
    ];

    const handleChange = (event: { target: { name: any; value: any; }; }) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}));
    };

    const handleSubmit = async (event: { preventDefault: () => void; }) => {
        setLoading(true);
        event.preventDefault();

        const templateParams = {
            from_name: inputs.name,
            to_name: 'elhousseyn arab',
            message: inputs.message,
            subject: inputs.subject
        };

        await emailjs.send(app.EMAIL_SERVICE_ID, app.MAIL_TEMPLATE_ID, templateParams,app.EMAIL_PUBLIC_KEY)
            .then((result) => {
                setLoading(false);
                clear();
                if(result.status === 200)
                {
                  setSuccess(true);
                }else{
                    setFailed(true);
                }
            }, (error) => {
                setLoading(false);
                setFailed(true);
                console.log(error.text);
            });
    };

    const clear = () => {
        setInputs({name : '', message: '', subject: '', email: ''});
    }

    return(
        <>
            <section id="about" className="about">
                <div className="container" data-aos="fade-up">

                    <div className="section-title">
                        <h2>About</h2>
                        <p>Highly skilled software engineer with a +3 years experience and strong foundation in software development, coding, and problem-solving. Proficient in multiple programming languages and experienced in designing, developing, and maintaining complex software solutions. Adept at collaborating with cross-functional teams to deliver innovative and efficient software applications that meet business objectives. Strong analytical and communication skills, dedicated to staying current with emerging technologies and best practices in the software development industry.</p>
                    </div>

                    <div className="row">
                        <div className="col-lg-4">
                            <img src={profile} className="img-fluid" alt="" />
                        </div>
                        <div className="col-lg-8 pt-4 pt-lg-0 content">
                            <h3>Software engineer</h3>
                            <div className="row">
                                <div className="col-lg-6">
                                    <ul>
                                        <li><i className="bi bi-chevron-right"></i> <strong>Birthday:</strong> <span>20 June 1999</span></li>
                                        <li><i className="bi bi-chevron-right"></i> <strong>Phone:</strong> <span>+213 699687499</span></li>
                                        <li><i className="bi bi-chevron-right"></i> <strong>Degree:</strong> <span>Bachelor</span></li>
                                    </ul>
                                </div>
                                <div className="col-lg-6">
                                    <ul>
                                        <li><i className="bi bi-chevron-right"></i> <strong>Email:</strong> <span>contact@elhousseyn.com</span></li>
                                        <li><i className="bi bi-chevron-right"></i> <strong>Freelance:</strong> <span>Always available</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <section id="skills" className="skills section-bg">
                <div className="container" data-aos="fade-up">

                    <div className="section-title">
                        <h2>Skills</h2>
                    </div>

                    <div className="row skills-content">
                        <div className="col-lg-6">
                            {
                                frontend_stack.map((web, key) =>
                                    <div key={key} className="progress">
                                        <span className="skill">{web.title} <i className="val">{web.value}%</i></span>
                                        <div className="progress-bar-wrap">
                                            <div className="progress-bar" role="progressbar" style={{width: web.progress.now + '%'}} aria-valuenow={web.progress.now} aria-valuemin={web.progress.min} aria-valuemax={web.progress.max}></div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>

                        <div className="col-lg-6">
                            {
                                backend_stack.map((web, key) =>
                                    <div key={key} className="progress">
                                        <span className="skill">{web.title} <i className="val">{web.value}%</i></span>
                                        <div className="progress-bar-wrap">
                                            <div className="progress-bar" role="progressbar" style={{width: web.progress.now + '%'}} aria-valuenow={web.progress.now} aria-valuemin={web.progress.min} aria-valuemax={web.progress.max}></div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>

                    </div>

                </div>
            </section>

            <section id="resume" className="resume">
                <div className="container" data-aos="fade-up">

                    <div className="section-title">
                        <h2>Resume</h2>
                    </div>

                    <div className="row">
                        <div className="col-lg-6">
                            <h3 className="resume-title">Summary</h3>
                            <div className="resume-item pb-0">
                                <h4>Elhousseyn Arab</h4>
                                <p><em>Highly skilled software engineer with a +3 years experience and strong foundation in software development, coding, and problem-solving. Proficient in multiple programming languages and experienced in designing, developing, and maintaining complex software solutions. Adept at collaborating with cross-functional teams to deliver innovative and efficient software applications that meet business objectives. Strong analytical and communication skills, dedicated to staying current with emerging technologies and best practices in the software development industry.</em></p>
                                <ul>
                                    <li>(213) 699687499</li>
                                    <li>contact@elhousseyn.com</li>
                                </ul>
                            </div>

                            <h3 className="resume-title">Education</h3>
                            <div className="resume-item">
                                <h4>Bachelor in computer science</h4>
                                <h5>2017 - 2020</h5>
                                <p><em>Hassiba ben bouali algeria, chlef</em></p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <h3 className="resume-title">Professional Experience</h3>
                            {
                                resume.map((data, key) =>
                                    <div key={key} className="resume-item">
                                        <h4>{data.jobTitle}</h4>
                                        <h5>{data.startYear} - {data.endYear}</h5>
                                        <p><em>{data.workingPlace}</em></p>
                                        <ul>
                                            {
                                                data.responsibilities.map((responsibility, otherKey) =>
                                                <li key={otherKey}>{responsibility}</li>
                                                )
                                            }
                                        </ul>
                                    </div>
                                )
                            }
                        </div>
                    </div>

                </div>
            </section>

            <section id="contact" className="contact">
                <div className="container" data-aos="fade-up">

                    <div className="section-title">
                        <h2>Contact</h2>
                    </div>

                    <div className="row mt-1">

                        <div className="col-lg-4">
                            <div className="info">

                                <div className="email">
                                    <i className="bi bi-envelope"></i>
                                    <h4>Email:</h4>
                                    <p>contact@elhousseyn.com</p>
                                </div>

                                <div className="phone">
                                    <i className="bi bi-phone"></i>
                                    <h4>Call:</h4>
                                    <p>+213 699687499</p>
                                </div>

                            </div>

                        </div>

                        <div className="col-lg-8 mt-5 mt-lg-0">

                            <form method="post" role="form" onSubmit={handleSubmit} className="php-email-form">
                                <div className="row">
                                    <div className="col-md-6 form-group">
                                        <input
                                            type="text"
                                            name="name"
                                            className="form-control"
                                            id="name"
                                            placeholder="Your Name"
                                            value={inputs.name}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="col-md-6 form-group mt-3 mt-md-0">
                                        <input
                                            type="email"
                                            className="form-control"
                                            name="email"
                                            id="email"
                                            placeholder="Your Email"
                                            value={inputs.email}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="form-group mt-3">
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="subject"
                                        id="subject"
                                        placeholder="Subject"
                                        value={inputs.subject}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="form-group mt-3">
                                    <textarea
                                        className="form-control"
                                        name="message"
                                        rows={5}
                                        placeholder="Message"
                                        value={inputs.message}
                                        onChange={handleChange}
                                        required
                                    ></textarea>
                                </div>
                                <div style={{display: 'flex', justifyContent: 'center'}} className="my-3">
                                    {
                                        success &&
                                        <div className="alert alert-success" role="alert">
                                            Your message has been sent. Thank you!
                                        </div>
                                    }

                                    {
                                        failed &&
                                        <div className="alert alert-danger" role="alert">
                                            there's an internal error with our system please send a direct email we will fix this soon. !
                                        </div>
                                    }
                                </div>
                                <div className="text-center"><button disabled={loading} type="submit">

                                        <Oval
                                            height={50}
                                            width={50}
                                            color="#4fa94d"
                                            wrapperStyle={{}}
                                            wrapperClass=""
                                            visible={loading}
                                            ariaLabel='oval-loading'
                                            secondaryColor="#4fa94d"
                                            strokeWidth={2}
                                            strokeWidthSecondary={2}
                                        />
                                    {
                                        !loading && "Send Message"
                                    }
                                </button>
                                </div>
                            </form>

                        </div>

                    </div>

                </div>
            </section>
        </>
    );
};

export default Section;