import React, {useEffect} from 'react';
import Header from "../components/Header";
import Home from "../components/Home";
import Section from "../components/Section";
import Footer from "../components/Footer";

const Main = () => {
    return(
        <>
            <i className="bi bi-list mobile-nav-toggle d-lg-none"></i>
            <Header />
            <Home />
            <main id="main">
                <Section />
            </main>

            <Footer />

            <a href="#" className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a>
        </>
    );
}

export default Main;